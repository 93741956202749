import { AttachmentViewerState } from 'context/AttachmentViewer/interfaces';
import ContactsSection from 'components/Sections/Contacts/ContactsSection';
import ContactsCard from 'components/Sections/Contacts/ContactsCard';
import AttachmentsCard from 'components/Sections/Attachments/AttachmentsCard';
import AttachmentsSection from 'components/Sections/Attachments/AttachmentsSection';
import ChecklistCard from 'components/Sections/Checklist/ChecklistCard';
import ChecklistSection from 'components/Sections/Checklist/ChecklistSection';
import CalendarSection from 'components/Sections/Calendar/CalendarSection';
import { AttachmentType, AttachmentFileType } from 'components/Sections/Attachments/interfaces';
import Section, { ISection, SectionType } from 'components/Sections/Section';
import { Dispatch } from 'react';
import AttachmentsPage from 'components/Sections/Attachments/AttachmentsPage';
import ContactsPage from 'components/Sections/Contacts/ContactsPage';
import ChecklistPage from 'components/Sections/Checklist/ChecklistPage';
import MaterialSelectionCard from 'components/Sections/MaterialSelection/MaterialSelectionCard';
import MaterialSelectionsSection from 'components/Sections/MaterialSelection/MaterialSelectionSection';
import CustomContentSection from 'components/Sections/CustomContent/CustomContentSection';
import CustomContentCard from 'components/Sections/CustomContent/CustomContentCard';
import CustomContentPage from 'components/Sections/CustomContent/CustomContentPage';
import SectionCard from 'components/Sections/SectionCard/SectionCard';
import FormsPage from 'components/Sections/Forms/FormsPage';
import FormsSection from 'components/Sections/Forms/FormSection';
import CalendarCard from 'components/Sections/Calendar/CalendarCard';
import CalendarEventsPage from 'components/Sections/Calendar/CalendarEventsPage';
import FolderCard from 'components/Sections/Folder/FolderCard';
import FolderSection from 'components/Sections/Folder/FolderSection';
import FolderPage from 'components/Sections/Folder/FolderPage';

export const getAttachmentFileType = (attachment: AttachmentType): AttachmentFileType => {
  const { mimeType, signable, signed } = attachment;

  if (signable) return AttachmentFileType.SIGNABLE;
  else if (signed) return AttachmentFileType.SIGNED;

  if (!mimeType) return AttachmentFileType.OTHER;

  if (mimeType.includes('image')) return AttachmentFileType.IMAGE;
  else if (mimeType.includes('pdf')) return AttachmentFileType.PDF;
  else if (mimeType.includes('video')) return AttachmentFileType.VIDEO;

  return AttachmentFileType.OTHER;
};

export const createAttachmentViewerState = (
  attachment: AttachmentType,
  titleKey?: string
): AttachmentViewerState => {
  const type = getAttachmentFileType(attachment);
  const { name, url, xfdf, id, signable, signed } = attachment;

  return {
    document: {
      name,
      type,
      url,
      xfdf,
      id,
      signed
    },
    downloadLink: !signable || type === AttachmentFileType.IMAGE ? url : '',
    titleKey: titleKey || getDefaultTitleKey(attachment)
  };
};

export const openAttachmentModal = (
  attachment: AttachmentType,
  dispatch: Dispatch<AttachmentViewerState>
) => {
  const state = createAttachmentViewerState(attachment);
  const document = state.document;
  const type = document?.type;

  if (type === AttachmentFileType.OTHER) {
    window.open(attachment.url, '_blank');
    return;
  }

  dispatch({
    ...state,
    open: true,
    showDownloadButton: type === AttachmentFileType.IMAGE
  });
};

const getDefaultTitleKey = (attachment: AttachmentType): string => {
  const { signed, signable } = attachment;
  let key = 'attachments';

  if (signable) key += '.signDocument';
  else if (signed) key += '.signedDocument';
  else key += '.attachmentViewer';

  return key;
};

export const formatBytes = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
};

export interface SectionWithContent extends ISection {
  id: number;
  card?: JSX.Element;
  page?: JSX.Element | null;
}
export const createSectionsWithContent = (sections: ISection[]) => {
  return sections.map((s) => {
    const section = { ...s } as SectionWithContent;

    if (s.type === SectionType.ATTACHMENTS) {
      const attachmentSection = new AttachmentsSection(s);
      section.card = <AttachmentsCard section={attachmentSection} />;
      section.page = <AttachmentsPage section={attachmentSection} />;
    } else if (s.type === SectionType.CONTACTS) {
      const contactsSection = new ContactsSection(s);
      section.card = <ContactsCard section={contactsSection} />;
      section.page = <ContactsPage section={contactsSection} />;
    } else if (s.type === SectionType.CHECKLIST) {
      const checklistSection = new ChecklistSection(s);
      section.card = <ChecklistCard section={checklistSection} />;
      section.page = <ChecklistPage section={checklistSection} />;
    } else if (s.type === SectionType.MATERIAL_SELECTIONS) {
      const materialSelectionSection = new MaterialSelectionsSection(s);
      section.card = <MaterialSelectionCard section={materialSelectionSection} />;
      section.page = null;
    } else if (s.type === SectionType.CUSTOM_CONTENT) {
      const customContentSection = new CustomContentSection(s);
      section.card = <CustomContentCard section={customContentSection} />;
      section.page = <CustomContentPage section={customContentSection} />;
    } else if (s.type === SectionType.SURVEYS) {
      const formsSection = new FormsSection(s);
      section.card = <FormsPage section={formsSection} />;
      section.page = null;
    } else if (s.type === SectionType.CALENDAR) {
      const calendarSection = new CalendarSection(s);
      section.card = <CalendarCard section={calendarSection} />;
      section.page = <CalendarEventsPage section={calendarSection} />;
    } else if (s.type === SectionType.FOLDER) {
      const folderSection = new FolderSection(s);
      section.card = <FolderCard section={folderSection} />;
      section.page = <FolderPage section={folderSection} />;
    } else {
      // Section types that have not yet been implemented.
      section.card = <SectionCard section={new Section(s)} noActionButton={true} />;
      section.page = null;
    }

    return section;
  });
};

export const calculateNewOrderNumber = (
  newOrder: string,
  orderNumber: number,
  sectionsLength: number
) => {
  const isFirstSection = orderNumber === 0;
  const isLastSection = orderNumber === sectionsLength - 1;

  return newOrder === 'up'
    ? isFirstSection
      ? sectionsLength - 1
      : orderNumber - 1
    : isLastSection
    ? 0
    : orderNumber + 1;
};

export const contentToHtmlElement = (html: string) => {
  const template = document.createElement('template');
  template.innerHTML = html.trim();
  return template.content;
};

// strips size (100KB) out of attachment name
export const stripContentAttachmentName = (name: string) => {
  const attachmentSizeLength = name.lastIndexOf('(') - 1;
  return attachmentSizeLength > 0 ? String(name.substring(0, attachmentSizeLength)) : name;
};
