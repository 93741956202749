import styles from './styles';
import { Grid, IconButton, Typography } from '@material-ui/core';
import FormsAccordions from './FormsAccordions/FormsAccordions';
import { useContext, useEffect, useState } from 'react';
import useT from 'hooks/useT';
import { ProjectContext } from 'context/Project/ProjectContext';
import { SectionContext } from 'context/Section/SectionContext';
import { AppContext } from 'context/App/AppContext';
import FormsSection from './FormSection';
import { Button, ButtonType, IconKeys } from '@groupbuilderoy/gb-components-library';
import { FormsContext } from 'context/Forms/FormsContext';
import CreateOrViewForm from './CreateFormItem/CreateOrViewForm';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteFormItem } from 'axiosInstances';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { ModalMessageContext } from 'context/ModalMessage/ModalMessageContext';
import FlashNotification from 'components/FlashNotification/FlashNotification';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import SectionCard from '../SectionCard/SectionCard';
import Section from '../Section';

interface Props {
  section: FormsSection;
}

const FormsPage = ({ section }: Props) => {
  const t = useT();
  const classes = styles();
  const { projectState } = useContext(ProjectContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { appState } = useContext(AppContext);
  const { sectionState, dispatch: dispatchSectionState } = useContext(SectionContext);
  const { formsState, dispatch } = useContext(FormsContext);
  const { organisationId, tenantId } = appState.startParameters;
  const { modalMessageState, dispatch: dispatchModalMessage } = useContext(ModalMessageContext);
  const [deleteFailed, setDeleteFailed] = useState<boolean>(false);

  const { selectedApartment } = apartmentState;
  const { selectedProject } = projectState;

  useEffect(() => {
    dispatchSectionState({ ...sectionState, showBackButton: false });
  }, []); // eslint-disable-line

  const handleDeleteSection = async () => {
    try {
      await deleteFormItem({
        token: String(appState.token),
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        projectId: String(selectedProject?.id),
        apartmentId: String(selectedApartment?.id.toString()),
        formId: Number(formsState.selectedFormType?.id),
        filledFormId: Number(formsState.selectedForm?.id)
      });
      dispatch({
        ...formsState,
        selectedForm: null,
        selectedFormType: null,
        creatingNewForm: false
      });
    } catch (e: any) {
      console.error(`Failed to delete section, error: ${e.message}`);
      setDeleteFailed(true);
    } finally {
      dispatchModalMessage({
        ...modalMessageState,
        isOpen: false
      });
    }
  };

  const closeFlashNotification = () => {
    setDeleteFailed(false);
  };

  return (
    <>
      <FlashNotification
        open={deleteFailed}
        message={t('forms.alert.formDeleteFailed')}
        onClose={closeFlashNotification}
      />
      <SectionCard
        section={new Section({ ...section, name: '', description: '' })}
        loading={false}
        error={false}
        noActionButton={true}
        autoHeight
      >
        <>
          {formsState.selectedFormType && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Button
                style={{ margin: '1rem 0' }}
                type={
                  appState.tenantSettings?.organisationId === 'srv'
                    ? ButtonType.contained
                    : ButtonType.outlined
                }
                caption={t('back')}
                onClick={() => {
                  dispatch({
                    ...formsState,
                    selectedForm: null,
                    selectedFormType: null,
                    creatingNewForm: false
                  });
                }}
                startIcon={IconKeys.longArrowLeft}
              />
              <IconButton
                data-testid='cj3-dropzone-file-delete'
                edge='end'
                style={{ margin: 'auto 0' }}
                aria-label='delete'
                onClick={() => {
                  dispatchModalMessage({
                    icon: <ReportProblemOutlinedIcon />,
                    title: t('modal.deleteFormCaption'),
                    description: t('modal.deleteFormDescription'),
                    isOpen: true,
                    onApprove: handleDeleteSection
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          )}
          <Grid container direction='column' style={{ marginTop: '-32px' }}>
            <Grid container direction='row' className={classes.topButtonRow}></Grid>
            {!formsState.selectedFormType && (
              <Grid className={classes.headingContainer}>
                <Typography variant='h5'>{section.name}</Typography>

                {section.description && (
                  <Typography className={classes.subtitle}>{section.description}</Typography>
                )}
              </Grid>
            )}

            <Grid item className={classes.content}>
              {formsState.selectedFormType ? (
                <CreateOrViewForm />
              ) : (
                <FormsAccordions section={section} />
              )}
            </Grid>
          </Grid>
        </>
      </SectionCard>
    </>
  );
};

export default FormsPage;
